export default class {
    static DivideTextAndNumbers(v) {
        let array = v.split("")
        let number = ""
        let word = ""
        let numbers = ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9']
        for (let i = 0; i < array.length; i++) {
            const item = array[i];
            if (numbers.indexOf(item) !== -1) {
                number += item
            } else {
                if (item !== ',') word += item
            }
        }
        return {
            number: parseFloat(number),
            word
        }
    }

    static fontSize(size) {
        // 获取屏幕宽度
        let width = document.body.clientWidth;
        const ratio = width / 1920
        // 取下整
        return parseInt(size * ratio)
    }

    static findCloseNum(arr, num) { //找出最接近的值
        var index = 0; // 保存最接近数值在数组中的索引
        var old_value = Number.MAX_VALUE; // 保存差值绝对值，默认为最大数值
        for (var i = 0; i < arr.length; i++) {
            var new_value = Math.abs(arr[i] - num); // 新差值
            if (new_value <= old_value) { // 如果新差值绝对值小于等于旧差值绝对值，保存新差值绝对值和索引
                if (new_value === old_value && arr[i] < arr[index]) { // 如果数组中两个数值跟目标数值差值一样，取大
                    continue;
                }
                index = i;
                old_value = new_value;
            }
        }
        return arr[index] // 返回最接近的数值
    }

    // static pageLB(){//页面轮播

    // }


} 