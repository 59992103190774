<template>
    <div class="page">
        <dv-loading class="pageLoading" v-if="loading">Loading...</dv-loading>
        <div class="left">
            <div class="header">
                <div class="t" @click="$router.back()">巨烨健康管理云平台</div>
                <div class="t1">ZAYATA HEALTH SAAS PLATFROM</div>
            </div>
            <div class="bottom">
                <div class="cardContainer card1">
                    <div class="title">激活设备总数</div>
                    <div class="subT">Active Devices Amount</div>
                    <div id="chart" ref="chart1" style="margin-top:0.1rem"></div>
                </div>
                <div class="cardContainer card2" style="padding-bottom:0">
                    <div class="title">设备类型激活总数排行</div>
                    <div class="subT">Active Devices Rank</div>
                    <div id="chart">
                        <div class="item" v-for="(item,index) in stat_02" :key="index" :style="{background:(index%2)===0?'rgba(29, 201, 183, 0.05)':''}">
                            <div class="itemLeft">
                                <div class="imgContainer">
                                    <img v-if="index===0" src="../assets/image/dev1.png" />
                                    <img v-if="index===1" src="../assets/image/dev2.png" />
                                    <img v-if="index===2" src="../assets/image/dev3.png" />
                                    <img v-if="index===3" src="../assets/image/dev4.png" />
                                </div>
                                <div class="dec">
                                    <div>{{item.prdtName}}</div>
                                    <div>{{item.supplierName}}</div>
                                </div>
                            </div>
                            <div class="v">{{item.userAmount}}</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="middle">
            <div class="cardContainer card1" style="padding-bottom:0">
                <div class="title">注册用户数</div>
                <div class="subT">Registered Users Amount</div>
                <div id="chart" ref="chart2" style="margin-top:0.1rem"></div>
            </div>
            <div class="cardContainer card2" style="padding-bottom:0">
                <div class="title">7日活跃用户数</div>
                <div class="subT">7-Day Active Users</div>
                <div id="chart" ref="chart3" style="margin-top:0.1rem"></div>
            </div>
            <div class="cardContainer card3" style="padding-bottom:0">
                <div class="title">按地区用户分布数</div>
                <div class="subT">Users by Region</div>
                <div id="chart" ref="chart4" style="margin-top:0.1rem"></div>
            </div>
        </div>
        <div class="right">
            <div class="cardContainer card1">
                <div class="title">年订单量趋势</div>
                <div class="subT">Year Trend of Orders</div>
                <div id="chart" ref="chart5" style="margin-top:0.1rem"></div>
            </div>
            <div class="cardContainer card2" style="padding-bottom:0">
                <div class="title">30日设备采购订单排名</div>
                <div class="subT">30-day Device Sales Rank</div>
                <div id="chart" style="margin-top:0.1rem">
                    <div :class="['item','bj'+item.rank]" v-for="(item,index) in stat_07" :key="index" :style="{ width:item.width}">
                        <template v-if="item.rank==1||item.rank==2||item.rank==3">
                            <img v-if="item.rank==1" src="../assets/image/no1.png">
                            <img v-if="item.rank==2" src="../assets/image/no2.png">
                            <img v-if="item.rank==3" src="../assets/image/no3.png">
                        </template>
                        <img v-else src="../assets/image/no.png">
                        <div class="no">{{item.rank}}</div>
                        <div class="dec">
                            <div class="t">{{item.customerName}}</div>
                            <div class="t1">{{item.Amount}}台</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import ut from '@/comm/ut'
    import * as echarts from 'echarts';
    import worldData from "./json/china.json";
    echarts.registerMap("china", worldData);
    import axios from '@/axios/index'

    export default {
        data() {
            return {
                widthMax: 0,
                refreshTimer: null,
                loading: true,
                chart1: null,
                chart2: null,
                chart3: null,
                chart4: null,
                chart5: null,
                stat_01: [],
                stat_02: [],
                stat_03: [],
                stat_04: [],
                stat_05: [],
                stat_06: [],
                stat_07: [],
            }
        },
        watch: {
            '$store.state.onresizeNumber': {
                handler() {
                    if (this.chart1) { //激活设备总数
                        this.chart1.clear()
                        this.setChart1()
                        this.chart1.resize();
                    }
                    if (this.chart2) {
                        this.chart2.clear()
                        this.setChart2()
                        this.chart2.resize();
                    }
                    if (this.chart3) {
                        this.chart3.clear()
                        this.setChart3()
                        this.chart3.resize();
                    }
                    if (this.chart4) {
                        this.chart4.clear()
                        this.setChart4()
                        this.chart4.resize();
                    }
                    if (this.chart5) {
                        this.chart5.clear()
                        this.setChart5()
                        this.chart5.resize();
                    }
                }
            }
        },
        async mounted() {
            await this.getData()
            this.loading = false
        },


        methods: {
            async getData() {
                let response = await axios.request({
                    url: "/api/dataworks.super/dashboard",
                    method: "GET"
                })
                let { stat_01 = [], stat_02 = [], stat_03 = [], stat_04 = [], stat_05 = [], stat_06 = [], stat_07 = [], refresh = 300 } = response.data.data
                if (this.refreshTimer) clearInterval(this.refreshTimer)
                this.refreshTimer = setInterval(this.getData, refresh * 1000);
                this.stat_01 = stat_01
                this.stat_02 = stat_02
                this.stat_03 = stat_03
                this.stat_04 = stat_04
                this.stat_05 = stat_05
                this.stat_06 = stat_06
                stat_07.sort((a, b) => a.rank - b.rank)
                this.widthMax = 0
                stat_07.forEach(item => {
                    if (parseInt(item.Amount) > this.widthMax) this.widthMax = parseInt(item.Amount)
                })
                stat_07.forEach(item => {
                    item.width = parseInt(item.Amount) / this.widthMax * 100 + '%'
                    console.log(item.width);
                })
                this.stat_07 = stat_07 // stat_07: "30日设备采购订单排名"
                this.setChart1()
                this.setChart2()
                this.setChart3()
                this.setChart4()
                this.setChart5()
            },
            setChart1() { //激活设备总数
                this.chart1 = echarts.init(this.$refs.chart1)
                let data = []
                let seriesData1 = []
                let xAxisData = []
                for (let i = 0; i < this.stat_01.length; i++) {
                    const item = this.stat_01[i];
                    xAxisData.push(`${item.year}`)
                    data.push({
                        value: item.activeDeviceAmount,
                    })

                    seriesData1.push({
                        value: item.activeDeviceAmount,
                    })
                }
                this.chart1.setOption({
                    grid: {
                        left: -ut.fontSize(15),
                        top: '12.5%',
                        width: '98%',
                        height: '87.5%',
                        containLabel: true
                    },
                    tooltip: {
                        show: true,
                    },
                    xAxis: {
                        data: xAxisData,
                        boundaryGap: false,
                        axisLine: {
                            show: false
                        },
                        axisLabel: {
                            textStyle: {
                                fontSize: ut.fontSize(14),
                                color: 'rgba(255, 255, 255, 0.6)'
                            },
                            // formatter: (params) => {
                            //     return params
                            // }
                        },
                        axisTick: {
                            show: false
                        }
                    },
                    yAxis: {
                        show: false
                    },
                    series: [
                        {
                            type: 'bar',
                            name: '激活设备总数',
                            tooltip: {
                                show: false
                            },
                            animation: true,
                            barWidth: 1.4,
                            hoverAnimation: false,
                            data,
                            itemStyle: {
                                normal: {
                                    color: 'rgba(255, 255, 255, 0.08)',
                                    label: {
                                        show: false
                                    }
                                }
                            }
                         },
                        {
                            type: 'line',
                            name: '激活设备总数',
                            smooth: true,
                            symbolSize: 10,
                            animation: false,
                            lineWidth: 1.2,
                            hoverAnimation: false,
                            data: seriesData1,
                            symbol: 'circle',
                            itemStyle: {
                                normal: {
                                    color: 'rgba(29, 201, 183, 1)',
                                    shadowBlur: 40,
                                    label: {
                                        distance: 0,
                                        show: true,
                                        position: 'top',
                                        textStyle: {
                                            color: '#FFFFFF',
                                        },
                                        // formatter: (params) => {
                                        //     return `{a|${params.value}}\n{b|}`
                                        // },
                                        fontSize: ut.fontSize(14),
                                        // rich: {
                                        //     a: {
                                        //         color: "#FFFFFF",
                                        //         verticalAlign: "middle",
                                        //         padding: [6, 12, 3, 12],
                                        //         borderRadius: 15,
                                        //         backgroundColor: "rgba(29, 201, 183, 1)",
                                        //         textShadowBlur: 0,
                                        //         shadowBlur: 0
                                        //         // backgroundColor:{
                                        //         //     image:"https://fastly.jsdelivr.net/gh/apache/echarts-website@asf-site/zh/images/logo.png?_v_=20200710_1"
                                        //         // }
                                        //     },
                                        //     b: {
                                        //         lineHeight: 9,
                                        //         align: "center",
                                        //         textShadowBlur: 0,
                                        //         shadowBlur: 0,
                                        //         textShadowOffsetY: -10,
                                        //         backgroundColor: {
                                        //             image: require("@/assets/image/sjx.png")
                                        //         }

                                        //     }
                                        // }
                                    }
                                },

                            },
                            areaStyle: {
                                normal: {
                                    color: new echarts.graphic.LinearGradient(
                                        0, 0, 0, 1, //渐变色在下面修改，这里是透明度
                                         [{
                                                offset: 1,
                                                color: 'rgba(29, 201, 183, 0)'
                                            },
                                            {
                                                offset: 0,
                                                color: 'rgba(29, 201, 183, 1)'
                                            },
                                     ]
                                    ),
                                    opacity: 0.4
                                }
                            }
                        }
                    ]
                })
            },
            setChart2() { //注册用户数
                this.chart2 = echarts.init(this.$refs.chart2)
                let data = []
                let seriesData1 = []
                let xAxisData = []
                for (let i = 0; i < this.stat_03.length; i++) {
                    const item = this.stat_03[i];
                    xAxisData.push(`${item.year}`)
                    data.push({
                        value: item.rgstUserAmount,
                    })

                    seriesData1.push({
                        value: item.rgstUserAmount,
                    })
                }
                this.chart2.setOption({
                    grid: {
                        left: -ut.fontSize(15),
                        top: '12.5%',
                        width: '98%',
                        height: '87.5%',
                        containLabel: true
                    },
                    tooltip: {
                        show: true,
                    },
                    xAxis: {
                        data: xAxisData,
                        boundaryGap: false,
                        axisLine: {
                            show: false
                        },
                        axisLabel: {
                            textStyle: {
                                fontSize: ut.fontSize(14),
                                color: 'rgba(255, 255, 255, 0.6)'
                            },
                            // formatter: (params) => {
                            //     return params
                            // }
                        },
                        axisTick: {
                            show: false
                        }
                    },
                    yAxis: {
                        show: false
                    },
                    series: [
                        {
                            type: 'bar',
                            name: '注册用户数',
                            tooltip: {
                                show: false
                            },
                            animation: false,
                            barWidth: 1.4,
                            hoverAnimation: false,
                            data,
                            itemStyle: {
                                normal: {
                                    color: 'rgba(255, 255, 255, 0.08)',
                                    label: {
                                        show: false
                                    }
                                }
                            }
                         },
                        {
                            type: 'line',
                            name: '注册用户数',
                            smooth: false,
                            symbolSize: 10,
                            animation: false,
                            lineWidth: 1.2,
                            hoverAnimation: false,
                            data: seriesData1,
                            symbol: 'circle',
                            itemStyle: {
                                normal: {
                                    color: 'rgba(255, 184, 34, 1)',
                                    shadowBlur: 40,
                                    label: {
                                        distance: 0,
                                        show: true,
                                        position: 'top',
                                        textStyle: {
                                            color: '#FFFFFF',
                                        },
                                        fontSize: ut.fontSize(14),
                                    }
                                },

                            },
                            areaStyle: {
                                normal: {
                                    color: new echarts.graphic.LinearGradient(
                                        0, 0, 0, 1, //渐变色在下面修改，这里是透明度
                                         [{
                                                offset: 1,
                                                color: 'rgba(255, 184, 34, 0)'
                                            },
                                            {
                                                offset: 0,
                                                color: 'rgba(255, 184, 34, 1)'
                                            },
                                     ]
                                    ),
                                    opacity: 0.4
                                }
                            }
                        }
                    ]
                })
            },
            setChart3() { //7日活跃用户数
                this.chart3 = echarts.init(this.$refs.chart3)
                let data = []
                let seriesData1 = []
                let xAxisData = []
                for (let i = 0; i < this.stat_04.length; i++) {
                    const item = this.stat_04[i];
                    let dateArry = item.date.split("-")
                    xAxisData.push(`${dateArry[1]}-${dateArry[2]}\n${dateArry[0]}`)
                    data.push({
                        value: item.sdActiveUser,
                    })

                    seriesData1.push({
                        value: item.sdActiveUser,
                    })
                }
                this.chart3.setOption({
                    grid: {
                        left: -ut.fontSize(15),
                        top: '12.5%',
                        width: '98%',
                        height: '87.5%',
                        containLabel: true
                    },
                    tooltip: {
                        show: true,
                    },
                    xAxis: {
                        data: xAxisData,
                        boundaryGap: false,
                        axisLine: {
                            show: false
                        },
                        axisLabel: {
                            textStyle: {
                                fontSize: ut.fontSize(14),
                                color: 'rgba(255, 255, 255, 0.6)'
                            },
                            // formatter: (params) => {
                            //     return params
                            // }
                        },
                        axisTick: {
                            show: false
                        }
                    },
                    yAxis: {
                        show: false
                    },
                    series: [
                        {
                            type: 'bar',
                            name: '注册用户数',
                            tooltip: {
                                show: false
                            },
                            animation: false,
                            barWidth: 1.4,
                            hoverAnimation: false,
                            data,
                            itemStyle: {
                                normal: {
                                    color: 'rgba(255, 255, 255, 0.08)',
                                    label: {
                                        show: false
                                    }
                                }
                            }
                         },
                        {
                            type: 'line',
                            name: '注册用户数',
                            smooth: false,
                            symbolSize: 10,
                            animation: false,
                            lineWidth: 1.2,
                            hoverAnimation: false,
                            data: seriesData1,
                            symbol: 'circle',
                            itemStyle: {
                                normal: {
                                    color: 'rgba(29, 201, 183, 1)',
                                    shadowBlur: 40,
                                    label: {
                                        distance: 0,
                                        show: true,
                                        position: 'top',
                                        textStyle: {
                                            color: '#FFFFFF',
                                        },
                                        fontSize: ut.fontSize(14),
                                    }
                                },

                            },
                            areaStyle: {
                                normal: {
                                    color: new echarts.graphic.LinearGradient(
                                        0, 0, 0, 1, //渐变色在下面修改，这里是透明度
                                         [{
                                                offset: 1,
                                                color: 'rgba(29, 201, 183, 0)'
                                            },
                                            {
                                                offset: 0,
                                                color: 'rgba(29, 201, 183, 1)'
                                            },
                                     ]
                                    ),
                                    opacity: 0.4
                                }
                            }
                        }
                    ]
                })
            },
            setChart4() { //按地区用户分布数
                this.chart4 = echarts.init(this.$refs.chart4);
                let userAmountData = []
                let cityData = []
                for (let i = 0; i < this.stat_05.length; i++) {
                    const item = this.stat_05[i];
                    userAmountData.push(item.userAmount)
                    cityData.push(`${item.city}`)
                }

                let series = [{
                        name: '溯源上报设备总数',
                        type: 'bar',
                        emphasis: {
                            focus: 'series'
                        },
                        itemStyle: {
                            borderRadius: [5, 5, 0, 0]
                        },
                        data: userAmountData
                    }
                ]
                this.chart4.setOption({
                    tooltip: {
                        trigger: 'axis',
                        axisPointer: {
                            type: 'shadow'
                        }
                    },
                    color: ["rgba(47, 231, 211, 0.8)"],
                    grid: {
                        top: '20px',
                        left: '0',
                        right: '0',
                        bottom: '0',
                        containLabel: true
                    },

                    xAxis: [{
                        type: 'category',
                        axisTick: { show: false },
                        data: cityData,
                        axisLabel: {
                            fontSize: ut.fontSize(10),
                            color: "rgba(255, 255, 255, 0.6)"
                        },
                        axisLine: {
                            lineStyle: {
                                color: "rgba(255, 255, 255, 0.2)"
                            }
                        },

                    }],
                    yAxis: [{
                        axisLabel: {
                            show: false,
                        },
                        splitLine: {
                            lineStyle: {
                                color: "rgba(255, 255, 255, 0.08)"
                            }
                        },
                        type: 'value'
                    }],
                    series
                })
            },
            setChart5() { //年订单量趋势
                this.chart5 = echarts.init(this.$refs.chart5)
                let data = []
                let seriesData1 = []
                let xAxisData = []
                for (let i = 0; i < this.stat_06.length; i++) {
                    const item = this.stat_06[i];
                    xAxisData.push(`${item.year}`)
                    data.push({
                        value: item.yrTrendOrder,
                    })

                    seriesData1.push({
                        value: item.yrTrendOrder,
                    })
                }
                this.chart5.setOption({
                    grid: {
                        left: -ut.fontSize(15),
                        top: '12.5%',
                        width: '98%',
                        height: '87.5%',
                        containLabel: true
                    },
                    tooltip: {
                        show: true,
                    },
                    xAxis: {
                        data: xAxisData,
                        boundaryGap: false,
                        axisLine: {
                            show: false
                        },
                        axisLabel: {
                            textStyle: {
                                fontSize: ut.fontSize(14),
                                color: 'rgba(255, 255, 255, 0.6)'
                            },
                            // formatter: (params) => {
                            //     return params
                            // }
                        },
                        axisTick: {
                            show: false
                        }
                    },
                    yAxis: {
                        show: false
                    },
                    series: [
                        {
                            type: 'bar',
                            name: '年订单量',
                            tooltip: {
                                show: false
                            },
                            animation: false,
                            barWidth: 1.4,
                            hoverAnimation: false,
                            data,
                            itemStyle: {
                                normal: {
                                    color: 'rgba(255, 255, 255, 0.08)',
                                    label: {
                                        show: false
                                    }
                                }
                            }
                         },
                        {
                            type: 'line',
                            name: '年订单量',
                            smooth: true,
                            symbolSize: 10,
                            animation: false,
                            lineWidth: 1.2,
                            hoverAnimation: false,
                            data: seriesData1,
                            symbol: 'circle',
                            itemStyle: {
                                normal: {
                                    color: 'rgba(29, 201, 183, 1)',
                                    shadowBlur: 40,
                                    label: {
                                        distance: 0,
                                        show: true,
                                        position: 'top',
                                        textStyle: {
                                            color: '#FFFFFF',
                                        },
                                        // formatter: (params) => {
                                        //     return `{a|${params.value}}\n{b|}`
                                        // },
                                        fontSize: ut.fontSize(14),
                                        // rich: {
                                        //     a: {
                                        //         color: "#FFFFFF",
                                        //         verticalAlign: "middle",
                                        //         padding: [6, 12, 3, 12],
                                        //         borderRadius: 15,
                                        //         backgroundColor: "rgba(29, 201, 183, 1)",
                                        //         textShadowBlur: 0,
                                        //         shadowBlur: 0
                                        //         // backgroundColor:{
                                        //         //     image:"https://fastly.jsdelivr.net/gh/apache/echarts-website@asf-site/zh/images/logo.png?_v_=20200710_1"
                                        //         // }
                                        //     },
                                        //     b: {
                                        //         lineHeight: 9,
                                        //         align: "center",
                                        //         textShadowBlur: 0,
                                        //         shadowBlur: 0,
                                        //         textShadowOffsetY: -10,
                                        //         backgroundColor: {
                                        //             image: require("@/assets/image/sjx.png")
                                        //         }

                                        //     }
                                        // }
                                    }
                                },

                            },
                            areaStyle: {
                                normal: {
                                    color: new echarts.graphic.LinearGradient(
                                        0, 0, 0, 1, //渐变色在下面修改，这里是透明度
                                         [{
                                                offset: 1,
                                                color: 'rgba(29, 201, 183, 0)'
                                            },
                                            {
                                                offset: 0,
                                                color: 'rgba(29, 201, 183, 1)'
                                            },
                                     ]
                                    ),
                                    opacity: 0.4
                                }
                            }
                        }
                    ]
                })
            },
        },
        destroyed() {
            if (this.refreshTimer) clearInterval(this.refreshTimer)
            if (this.chart1) this.chart1.dispose()
            if (this.chart2) this.chart2.dispose()
            if (this.chart3) this.chart3.dispose()
            if (this.chart4) this.chart4.dispose()
            if (this.chart5) this.chart5.dispose()
        }
    }
</script>
<style lang="less" scoped>
    .pageLoading {
        background-color: #1330b8;
        color: #FFFFFF;
        position: fixed;
        width: 100%;
        height: 100%;
        z-index: 99;
        top: 0;
        left: 0;
    }


    .page {
        background-image: url('../assets/image/header1.png');
        background-size: 100% auto;
        background-repeat: no-repeat;
        background-color: #1330b8;
        display: flex;
        position: relative;
        padding-bottom: .41rem;
        box-sizing: border-box;
    }

    #chart {
        width: 100%;
        height: 100%;
        box-sizing: border-box;
    }

    .cardContainer {
        width: 100%;
        height: 100%;
        overflow: hidden;
        padding: .2rem;
        box-sizing: border-box;
        background: rgba(17, 44, 171, 0.8);
        display: flex;
        flex-direction: column;
        position: relative;

        .title {
            padding-left: .12rem;
            font-size: .28rem;
            font-weight: 800;
            color: #FFFFFF;
            position: relative;

            &::after {
                content: "";
                width: .04rem;
                height: 70%;
                left: 0;
                top: 20%;
                background: #1DC9B7;
                position: absolute;
            }
        }

        .subT {
            font-size: .16rem;
            font-weight: 400;
            margin-left: 0.12rem;
            color: rgba(29, 201, 183, 0.8);
        }
    }

    .left {
        flex-shrink: 0;
        display: flex;
        flex-direction: column;
        height: 100%;
        // border: 1px solid red;
        box-sizing: border-box;

        .header {
            flex-shrink: 0;
            width: 6.06rem;
            position: relative;
            height: 1.31rem;
            // border: 1px solid red;
            box-sizing: border-box;
            text-align: center;

            .t {
                font-size: .52rem;
                font-weight: 800;
                color: #FFFFFF;
                margin-top: .2rem;
                cursor: pointer;
            }

            .t1 {
                font-size: .14rem;
                font-weight: 400;
                color: #FFFFFF;
                margin-top: .11rem;
                letter-spacing: 0.08rem;
            }
        }

        .bottom {
            margin-top: 0.2rem;
            width: 6.4rem;
            padding-left: .4rem;
            flex: auto;
            overflow: hidden;
            // border: 1px solid red;
            box-sizing: border-box;
            display: flex;
            flex-direction: column;
            justify-content: space-between;

            .cardContainer {
                width: 6rem;

                &.card1 {
                    flex-shrink: 0;
                    width: 100%;
                    height: 3.77rem;
                    // border: 1px solid yellow;
                    box-sizing: border-box;
                }

                &.card2 {
                    margin-top: .21rem;
                    width: 100%;
                    flex: auto;
                    // border: 1px solid yellow;
                    box-sizing: border-box;

                    #chart {
                        margin-top: .2rem;
                        overflow-y: scroll;

                        // 滚动条样式
                        &::-webkit-scrollbar-track-piece {
                            background-color: transparent;
                        }

                        &::-webkit-scrollbar {
                            width: 8px;
                            height: 8px;
                            background-color: transparent;
                        }

                        &::-webkit-scrollbar-thumb {
                            // border-radius:0 0 5px 5px;
                            border-radius: 5px;
                            background-color: hsla(220, 4%, 58%, 0.1);
                        }

                        .item {
                            display: flex;
                            align-items: center;
                            justify-content: space-between;
                            padding: .14rem .2rem;
                            padding-left: 0;
                            box-sizing: border-box;
                            width: 100%;

                            .itemLeft {
                                display: flex;
                                align-items: center;

                                .imgContainer {
                                    width: 1rem;
                                    display: flex;
                                    justify-content: center;
                                    align-items: center;
                                    flex-shrink: 0;

                                    img {
                                        height: .63rem;
                                    }
                                }



                                .dec {

                                    div {
                                        font-size: .18rem;
                                        font-weight: 400;
                                        color: #FFFFFF;

                                        &:nth-child(2) {
                                            font-size: .14rem;
                                            font-weight: 400;
                                            color: #1DC9B7;
                                            margin-top: .04rem;
                                        }
                                    }
                                }
                            }



                            .v {
                                font-size: .28rem;
                                font-weight: 400;
                                color: #FFFFFF;
                            }
                        }
                    }
                }

            }
        }


    }

    .middle {
        flex-shrink: 0;
        margin: 0 .4rem;
        padding-top: .83rem;
        width: 5.5rem;
        display: flex;
        flex-direction: column;
        height: 100%;
        box-sizing: border-box;
        // border: 1px solid yellow;

        .cardContainer {
            background-color: unset;

            &.card1 {
                height: 3.44rem;
                flex-shrink: 0;
            }
        }
    }

    .right {
        margin-right: .4rem;
        padding-top: .83rem;
        flex: auto;
        height: 100%;
        display: flex;
        flex-direction: column;
        box-sizing: border-box;
        // border: 1px solid yellow;
        overflow: hidden;

        .cardContainer {

            &.card1 {
                height: 3.44rem;
                flex-shrink: 0;
            }

            &.card2 {
                flex: auto;
                // border: 1px solid red;
                box-sizing: border-box;
                background-color: unset;

                #chart {
                    overflow-y: scroll;

                    // 滚动条样式
                    &::-webkit-scrollbar-track-piece {
                        background-color: transparent;
                    }

                    &::-webkit-scrollbar {
                        width: 8px;
                        height: 8px;
                        background-color: transparent;
                    }

                    &::-webkit-scrollbar-thumb {
                        // border-radius:0 0 5px 5px;
                        border-radius: 5px;
                        background-color: hsla(220, 4%, 58%, 0.1);
                    }

                    .item {
                        position: relative;
                        width: 100%;
                        background-color: rgba(194, 22, 244, 0.6);
                        height: .38rem;
                        padding-left: .8rem;
                        box-sizing: border-box;
                        margin-top: .155rem;

                        &:first-of-type {
                            margin-top: 0;
                        }

                        &.bj1 {
                            background-color: rgba(255, 184, 34, 0.6);
                        }

                        &.bj2 {
                            background-color: rgba(29, 201, 183, 0.6);
                        }

                        &.bj3 {
                            background-color: rgba(30, 143, 255, 0.6);
                        }

                        img {
                            position: absolute;
                            left: 0;
                            top: 0;
                            width: .6rem;


                        }

                        .no {
                            width: .5rem;
                            position: absolute;
                            font-size: .28rem;
                            position: absolute;
                            font-weight: 400;
                            color: #FFFFFF;
                            left: 0;
                            text-align: center;
                            top: .05rem;
                            z-index: 1;
                        }

                        .dec {
                            line-height: .38rem;
                            font-size: .18rem;
                            font-weight: 400;
                            color: #FFFFFF;
                            display: flex;
                            align-items: center;

                            .t1 {
                                margin-left: .2rem;
                            }
                        }
                    }
                }

            }
        }
    }
</style>